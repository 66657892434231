import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector } from 'react-redux';
//import moment from 'moment';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
    TextField,
    Typography,
    Grid,
    CardHeader,
    FormControl,
    RadioGroup,
    Radio,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import { NewPageId } from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import Amplify, { API, graphqlOperation } from "aws-amplify";
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';
import { SendEmail } from '../utils/SendEmail';

const NewId = NewPageId("AM")

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");


const Profile = ({ className, ...rest }) => {
    const classes = useStyles();
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        Message: "",
        MessageNo: "",
        Personal: [],
        PrivateMessage: false,
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        NewId: NewId,
        PhotoService: [],
        Documents: [],
        ImageStateSet: [],
        Priority: "Medium",
        ManagerActiondisabled: true,
        ApprovalMessage: `Only Authorized Person from customer's organization can approve the work`,
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {

        try {

            let Priority = "Medium"
            let ManagerActiondisabled = true;
            let ApprovalMessage = `Only Authorized Person from ${rest.SREvent.posterId.CompanyName} can approve the work`;
            if (UserData.CompanyId === rest.SREvent.posterId.CompanyId) {
                ManagerActiondisabled = false;
                ApprovalMessage = "";
            }

            if (rest.SREvent.Priority && rest.SREvent.Priority !== "") {
                Priority = rest.SREvent.Priority;
            }

            setState(prevState => ({
                ...prevState,
                ManagerActiondisabled: ManagerActiondisabled,
                ApprovalMessage: ApprovalMessage,
                Priority: Priority,
            }));

        } catch (err) { }

    }, [rest.UpdateNumber])

    const SendValuesBack = () => {

        let TotalMessage = {
            Message: state.Message,
            Person: state.Personal,
            PrivateMessage: rest.Trigger1
        }

        if (rest && rest.SelectedValues) {
            rest.SelectedValues(TotalMessage)
        }

    }

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    //const PrivateMessageProcess = (e, name) => {

    //  if (name === "Personal" && e && e.length > 0 ) {

    //  }

    //  setState(prevState => ({
    //    ...prevState,
    //    [name]: e
    //  }));


    //}

    const GetCompanyName = (data) => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.field1,
            CompanyId: data.CompanyId,
        }));

        SetSelectCompany(false);

    }

    const SelectContractor = () => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: ContractorData.CompanyName,
            CompanyId: ContractorData.CompanyId,
        }));

        SetSelectCompany(false);

    }


    const SelectCompanyBack = () => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: UserData.CompanyName,
            CompanyId: UserData.CompanyId,
        }));

        SetSelectCompany(false);

    }

    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const ProcessMessage = (workstatus) => {

        let NotificationList = [];
        let PrivateMessageList = [UserData.key];
        console.log("AddMessage.state.Personal", state.Personal)
        //create a list for email and Private message
        if (state.Personal.length > 0) {
            state.Personal.map(each => {
                PrivateMessageList.push(each.key)
                NotificationList.push({
                    field1: each.field1,
                    email: each.email,
                    mobile: each.mobile,
                    phone: each.phone,
                    pk1: each.CompanyId,
                    sk1: each.key
                })
            })
        }
        console.log("AddMessage.NotificationList", NotificationList)
        //put keys only if Private message is true
        if (!state.PrivateMessage) {
            PrivateMessageList = [];
        }

        let SREvent = rest.SREvent;
        let ManagerApprovedBy = []
        if (SREvent.ManagerApprovedBy) {
            ManagerApprovedBy = SREvent.ManagerApprovedBy;
        }

        let EventData = SREvent.EventData;
        let NewEventData = [];
        let TotalLength = EventData.length;
        let NewEventAdd = {
            PhotoService: state.PhotoService,
            Documents: state.Documents,
            key: state.NewId,
            Body: `Manager ${workstatus}: ${state.Message}`,
            TimeStamp: currentTime,
            SenderName: UserData.field1,
            Action: workstatus,
            PosterId: UserData.key,
            avatar: UserData.avatar,
            SenderCompany: UserData.CompanyName,
            SenderCompanyType: UserData.CustomerType,
            SenderCompanyId: UserData.CompanyId,
            PrivateMessage: state.PrivateMessage,
            Priority: state.Priority,
            PrivateMessageList: PrivateMessageList,
            MessageRead: [UserData.key],
        }

        if (rest.UpdateNumber < TotalLength) {
            EventData.splice(rest.UpdateNumber + 1, 0, NewEventAdd);
            NewEventData = EventData;
        } else {
            NewEventData = [...EventData, NewEventAdd];
        }

        SREvent.EventData = NewEventData;
        SREvent.Priority = state.Priority;
        SREvent.ManagerApprovalStatus = workstatus;
        SREvent.ManagerApprovedBy = [...ManagerApprovedBy, NewEventAdd];

        if (rest.UpdateEvent) {
            rest.UpdateEvent(SREvent);
        }

        if (NotificationList.length > 0) {
            GoToLambda(NotificationList, SREvent, NewEventAdd);
        } else {

            if (rest.CloseDrawer) {
                rest.CloseDrawer();
            }

        }

        // console.log("ManagerApproved.SREvent.ManagerApprovedBy", SREvent.ManagerApprovedBy)
    }

    //const GoToLambda = (NotificationList, event, NewEventAdd) => {

    //    try {

    //        var emailstring = {

    //            title: event.title,
    //            avatar: NewEventAdd.avatar,
    //            BackgroundColor: "#eeeeee",
    //            ButtonColor: "#44872e",
    //            HeadingColor: "#44872e",
    //            ButtonTextColor: "#ffffff",
    //            ContractorName: NewEventAdd.SenderCompany,
    //            ActionButton: `https://bomrng.com/${event.PostId}`,
    //            ContractorLogo: event.ContractorData.dataJSON.avatar,
    //            ActionButtonTitle: "See Details Online",
    //            headerBanner: "https://images.pexels.com/photos/4050299/pexels-photo-4050299.jpeg?cs=srgb&dl=woman-sitting-on-the-floor-using-a-laptop-4050299.jpg&fm=jpg",
    //            PhotoService: NewEventAdd.PhotoService,
    //            Documents: NewEventAdd.Documents,
    //            SpecialInstructions: "",
    //            PreSelectList: [],
    //            UnsubscribeLink: "https://bomrng.com/UnsubscribeLink",
    //            // email: "ali.s.rehan@jci.com", //each person email
    //            // title: event.title,
    //            //  Message: NewEventAdd.Body,
    //            postHTML: NewEventAdd.Body,
    //            postTEXT: NewEventAdd.Body,
    //            PersonList: NotificationList,
    //            PostId: event.PostId,
    //            ContractorId: event.ContractorData.gsi2Pk2,
    //            CompanyId: event.PersonData.CompanyId,
    //            ReplyEmail: event.SMSFeedbackMessages.ReplyEmail

    //        };

    //        console.log("AddMessage.emailstring", emailstring)

    //        if (emailstring) {
    //            API.post("TriggerEmail", "/post", {
    //                body: { Records: emailstring }
    //            });
    //        }

    //    } catch (err) { }

    //}

    const GoToLambda = (PersonList, event, NewEventAdd) => {

        try {

            // let LocalDate = new Date(currentTime).toLocaleString();
            let ContractorSubDomain = `https://${ContractorData.SubDomain}.service-call-cloud.com`;
            //let title = `${state.SubscriptionName} - ${LocalDate}`;

            let title = event.title;
            let postHTML = NewEventAdd.Body;
            let postTEXT = NewEventAdd.Body;

            switch (rest.UpdateCategory) {
                case "Accepted":
                    title = `Accepted - ${title}`;
                    //postHTML = `<h3>Accepted by ${UserData.filed1}</h3> ${postHTML}`;
                    //postTEXT = `Accepted by ${UserData.filed1} \n\n ${postTEXT}`;
                    break;
                case "Canceled":
                    title = `Canceled - ${title}`;
                    //postHTML = `<h3>Canceled by ${UserData.filed1}</h3> ${postHTML}`;
                    //postTEXT = `Canceled by ${UserData.filed1} \n\n ${postTEXT}`;
                    break;
            }

            let SendEvent = {

                PhotoService: NewEventAdd.PhotoService,
                Documents: NewEventAdd.Documents,
                // SpecialInstructions: state.SpecialInstructions,
                PreSelect: [],
                title: title,
                PersonList: PersonList,
                postHTML: postHTML,
                postTEXT: postTEXT,
                ReplyEmail: "no-reply@service-call-cloud.com",
                PostId: event.PostId,

                ContractorAddress: ContractorData.Location.description,
                ContractorPhone: ContractorData.phone,
                ContractorLogo: ContractorData.avatar,
                ContractorName: ContractorData.CompanyName,

                UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
                ActionButton: `${ContractorSubDomain}/${event.PostId}`,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: true
            }

            console.log("SubscriptionAdd1.SendEmail", SendEvent)

            SendEmail(SendEvent);



        } catch (err) { }

    }

    const KeyArray = (data, cat) => {

        let newdata = data.target.value;

        setState(prevState => ({
            ...prevState,
            [cat]: newdata
        }));


    }

    //console.log("Requestor.ContractorData", ContractorData)
    //console.log("Requestor.UserData", UserData)
    //console.log("Requestor.cid", cid)
    //console.log("Requestor.state", state)
    console.log("AddMessage.props", rest)
    console.log("AddMessage.state", state)

    return (
        <Page className={classes.paper}>
            <Grid container spacing={1}>
                <Grid xs={12} item>

                    <Typography variant="h4">
                        {rest.DrawerTitle}
                    </Typography>
                </Grid>
                <Grid xs={12} item>

                    <TextField
                        label="Add Message"
                        name="Message"
                        value={state.Message || ""}
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Message")}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="Priority"
                            // defaultValue="High"
                            name="radio-buttons-group"
                            //onChange={(e) => KeyArray(e, "Priority")}
                            onChange={(data) => KeyArray(data, "Priority")}
                            value={state.Priority || ""}
                        >

                            <FormControlLabel value="Emergency" control={<Radio />} label="Emergency" />

                            <FormControlLabel value="High" control={<Radio />} label="High Priority" />

                            <FormControlLabel value="Medium" control={<Radio />} label="Medium Priority" />

                            <FormControlLabel value="Low" control={<Radio />} label="Low Priority" />

                        </RadioGroup>
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <Button
                        onClick={() => SetAddPersonal(!AddPersonal)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<AddCircleOutline />}
                    >
                        Send Email Notification
                    </Button>
                </Grid>

                {AddPersonal && (
                    <>
                        <Grid item xs={12} >
                            <Typography variant="h4">
                                {state.CurrentCompany}
                            </Typography>
                        </Grid>

                        {UserData.CustomerType === "Contractor" && (
                            <Grid item xs={12} >
                                <Button
                                    //disabled={activeStep === 0}
                                    variant="contained"
                                    onClick={() => SetSelectCompany(!SelectCompany)}
                                    className={classes.button}
                                >
                                    Change Company
                                </Button>
                            </Grid>
                        )}


                        {UserData.CustomerType === "Company" && state.CompanyId === UserData.CompanyId && (
                            <Grid item xs={12} >
                                <Button
                                    //disabled={activeStep === 0}
                                    variant="contained"
                                    onClick={() => SelectContractor()}
                                    className={classes.button}
                                >
                                    {`Select ${ContractorData.CompanyName} Personal`}
                                </Button>
                            </Grid>
                        )}

                        {UserData.CustomerType === "Company" && state.CompanyId === ContractorData.CompanyId && (
                            <Grid item xs={12} >
                                <Button
                                    //disabled={activeStep === 0}
                                    variant="contained"
                                    onClick={() => SelectCompanyBack()}
                                    className={classes.button}
                                >
                                    {`Select ${UserData.CompanyName} Personal`}
                                </Button>
                            </Grid>
                        )}



                        {SelectCompany && UserData.CustomerType === "Contractor" && (
                            <Grid
                                item
                                xs={12}
                            >
                                <AutoComplateBox
                                    //SaveDetails={rest.SaveDetails}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    Category="Company"
                                    FormType="Company"
                                    title="Company"
                                    // id={Newpageiddd}
                                    ShowEditButton={false}
                                    CompanyId={ContractorData.CompanyId}
                                    // ReturnBackData={e => console.log(e)}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    //FormData={each.FormData}
                                    onSubmit={(model) => GetCompanyName(model)}
                                />
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "Personal")}
                                CompanyId={state.CompanyId}
                                CompanyName={state.CurrentCompany}
                                title="Select Personal"
                                Category="Person"
                            // FormData={rest.FormData}
                            />
                        </Grid>

                    </>
                )}


                <Grid xs={12} item>
                    <Grid container spacing={1}>
                        <Grid xs={6} item>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={state.ManagerActiondisabled}
                                onClick={() => ProcessMessage("Approved")}
                            >
                                Approve
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                color="error"
                                variant="contained"
                                disabled={state.ManagerActiondisabled}
                                onClick={() => ProcessMessage("Rejected")}
                            >
                                Reject
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {state.ApprovalMessage !== "" && (
                    <Grid xs={12} item>

                        <Typography variant="h4" gutterBottom component="div">
                            {state.ApprovalMessage}
                        </Typography>
                    </Grid>
                )}

            </Grid>
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
