import React, { useState, useEffect } from 'react';
import {
    TextField, Backdrop,
    CircularProgress, Button, Typography, Grid
} from '@mui/material';
//import Autocomplete from '@mui/material/Autocomplete';
import { getAutoCompletedata, NewPageId } from '../utils/CommonGraphql';
import Chart1 from '../Charts/Chart1';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog(props) {

    let ResetValue = {
        SearchWords: "",
        filed1: ""
    }

    const [value, setValue] = React.useState(null);
    const [valueShow, setValueShow] = React.useState(null);
    const [open, toggleOpen] = React.useState(false);

    const initialState = {
        DataBack: [],
        //Category: "",
        //ShowSelected: false,
        //ShowAddButton: false,
        //anchor: "left",
        //SelectedValue: "",
        //Selectedtitle: "",
    }
    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    useEffect(() => {
        GetDatafromDB("Branch", props.CompanyId);
    }, [props.Category])

    const GetDatafromDB = async (Category, cid) => {
        SetLoading(true);
        let NewData = [];

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: "DataBack",
                    ArraySearchString: ":",
                }
            ]
        };

        let NewData2 = await getAutoCompletedata(DataString, cid);
        NewData = await NewData2;

        console.log("Autocomplete.NewData", NewData);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
    }

    //const CloseDrawer = (data) => {
    //    if (data && data !== undefined) {
    //        handleClose(data);
    //        GetDatafromDB(props.Category, props.CompanyId);
    //    } else {
    //        toggleOpen(false);
    //    }
        
    //}

    //const handleClose = (data) => {

    //    if (data && data !== undefined) {
    //        setValue(data)
    //        setValueShow(data.field1)
    //        console.log("AutoComplete.data", data)

    //        if (props.onSubmit) {
    //            props.onSubmit(data);
    //        }

    //        //Selection is used to bring all the data back
    //        if (props.Selection) {
    //            props.Selection(data);
    //        }
    //    }

    //    toggleOpen(false);
    //};

    //const ReturnBackData = (e) => {
    //    //Selection is used to bring all the data back
    //    console.log("Autocomplete.ReturnBackData", e);

    //    handleClose(e);

    //    if (props.Selection && e) {
    //        props.Selection(e);
    //    }
    //}

    console.log("AutoComplete.state", state)
    console.log("AutoComplete.props", props)

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
            >
                {state.DataBack && state.DataBack.length > 0 ? (
                    state.DataBack.map((each, indd)=>(

                        <Grid item xs={12} >
                            <Chart1
                                CompanyName={each.field1}
                                CompanyId={each.CompanyId}
                                key={each.key}
                                id={each.key}
                            />
                        </Grid>

                        ))
                    

                ) : null}

            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
